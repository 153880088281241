Zo = {};

Zo._loadFile = function(file)  {
    if(Zo.files_loaded == undefined || typeof Zo.files_loaded == undefined) {
        Zo.files_loaded = {};
    }
    
    if(Zo.files_loaded[file] != undefined && typeof Zo.files_loaded[file] != undefined) {
        return true;
    }
    
    jQuery.ajax({
        async: false,
        type: "GET",
        url: file,
        data: null,
        cache: true,
        success: function() {
            Zo.files_loaded[file] = true;
        },
        dataType: 'script'
    });
};

Zo.load = function(object_name) {
    if(arguments.length > 1) {
        for(var i = 0; i < arguments.length; i++) {
            Zo.load(arguments[i]);
        }
    }
    
    //console.log('Type der Klasse ' + object_name + ' ist: ' + typeof(window[object_name]));
    if(typeof(window[object_name]) !== 'function') {
        //console.log('Lade nach: '  + object_name);
        Zo._loadFile('/js/framework/' + object_name + '.js');
    } /*else {
        console.log('Gefunden: '  + object_name);
    }*/
};

Zo.loadPlugin = function(plugin_name) {
    if(arguments.length > 1) {
        for(var i = 0; i < arguments.length; i++) {
            Zo.loadPlugin(arguments[i]);
        }
    }
    
    if(! $.fn[plugin_name]) {
        //console.log('Lade nach: '  + plugin_name);
        Zo._loadFile('/js/framework/jquery.' + plugin_name + '.js');
    } /*else {
        console.log('Gefunden: '  + plugin_name);
    }*/
};

Zo.loadClass = function(class_name) {
    if(arguments.length > 1) {
        for(var i = 0; i < arguments.length; i++) {
            Zo.loadClass(arguments[i]);
        }
    }
    
    //console.log('Type der Klasse ' + class_name + ' ist: ' + typeof(window[class_name]));
    if(typeof(window[class_name]) !== 'function') {
        //console.log('Lade nach: '  + class_name);
        Zo._loadFile('/js/classes/' + class_name + '.js');
    } /*else {
        console.log('Gefunden: '  + class_name);
    }*/
};

Zo.loadScript = function(file_name) {
    if(arguments.length > 1) {
        for(var i = 0; i < arguments.length; i++) {
            Zo.loadScript(arguments[i]);
        }
    }
    
    Zo._loadFile(file_name);
};

Zo.get = function(object_name) {
    Zo.load(object_name);
    return new window[object_name](Array.prototype.slice.call(arguments, 1));
};