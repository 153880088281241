$(function() {
    Zo.load('AjaxDialog', 'EnteredAjaxForm');
    Zo.loadPlugin('myAjaxLoadingImage');

    $( ".js_flag-toogle-btn" ).click(function() {
        $( ".js_flag-mobil", $(this).parent() ).slideToggle( 500 );
        return false;
    });
    $( ".js_flag-mobil" ).hide();

    var focus_func = function () {
        if ($(this).val() == $(this).attr('data-placeholder')) {
            $(this)
                .val('')
                .removeClass('beschriftet')
                .addClass('unbeschriftet')
            ;
        }
    };

    var blur_func = function ()  {
        if ($(this).val() == '') {
            $(this)
                .val($(this).attr('data-placeholder'))
                .addClass('beschriftet')
                .removeClass('unbeschriftet')
            ;
        }
    };

    $( ".mobil-sub" ).click(function() {
        $(this).toggleClass('active');
        $(this).find('ul').slideToggle( 800 );
    });

    $('.footer-links , .footer-links-main, .footer-info-agb').on("click", function() 
	{
    if (typeof $(this).attr('data-href') !== 'undefined') {
    window.open($(this).attr('data-href'), this.getAttribute('data-target'));
}
});
});

    // LeafletJS (OSM Maps)
    // if (
    //     (
    //         (
    //             window.Travanto.breakpoints['>tablet-landscape']
    //             ||
    //             $('.rues-container').length > 0
    //         )
    //         &&
    //         (
    //             $('#map_osm').length > 0
    //             ||
    //             $('#dynamische_karte_osm').length > 0
    //             ||
    //             $('#karte').length > 0))
    //     ||
    //     $('#searchTextFieldSuchformular').length > 0
    //     ||
    //     $('.karte-static-form').length > 0
    // ) {
    //     window.initialize_leaflet = function() {
    //         window.Travanto.getPromise('leaflet-api-loaded').resolve();
    //     };
    //     if($('#searchTextFieldSuchformular').length > 0 || $('#searchTextField').length > 0) {
    //         script.src = 'https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=places&callback=initialize' + places_api_param;
    //     } else {
    //         script.src = '//maps.google.com/maps/api/js?callback=initialize' + places_api_param;
    //     }

    //     var leaflet = new Leaflet();
    //     leaflet.load(initialize_leaflet);
    // }
    // if (
    //     (
    //         window.Travanto.breakpoints['>tablet-landscape']
    //         ||
    //         $('.rues-container').length > 0
    //     )
    //     &&
    //     $('#map_osm').length > 0
    // ) {
    //     window.Travanto.getPromise('leaflet-api-loaded').done(function() {
    //         var map = new MapFacadeLeaflet('map_osm');
    //         window.Travanto.elements.map = map;
    //         map.setBounds(
    //             $('#map_osm').attr('data-n'),
    //             $('#map_osm').attr('data-e'),
    //             $('#map_osm').attr('data-s'),
    //             $('#map_osm').attr('data-w')
    //         );

    //         var objekte_layer = new TravantoObjekteLayerLeaflet(map);
    //         map.addLayer(objekte_layer);
    //         map.load();

    //         map.ready(function () {
    //             $('#update_map', '#suchformular').click(function () {
    //                 objekte_layer.clearMap();
    //                 objekte_layer.update();
    //             });

    //             window.Travanto.getPromise('map_osm').resolve();
    //         });
    //     });
    // }
    // - LeafletJS (OSM Maps)