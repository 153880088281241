(function($) {
	$.fn.FlowupLabels = function( options ){
	
		var defaults = {
        		// Useful if you pre-fill input fields or if localstorage/sessionstorage is used. 
				feature_onLoadInit:	true,
				// Class names used for focus and populated statuses
				class_focused: 		'focused',
				class_populated: 	'populated'
			},
			settings = $.extend({}, defaults, options);
        
        var inputs = 'input[type="text"], input[type="email"], input[type="phone"], input[type="number"], input[type="password"], input[type="tel"], textarea, select';
        
        $(inputs, this).attr('placeholder', '');
  
		return this.each(function(){
			var $scope  = $(this);
    
			$scope.on('focus.flowupLabelsEvt', inputs, function() {
				$(this).closest('.jsfl-wrap').addClass(settings.class_focused);
			})
			.on('blur.flowupLabelsEvt init.flowupLabelsEvt datepicker.flowupLabelsEvt change.flowupLabelsEvt', inputs,
				function(e) {
					var $this = $(this);
					
					if (   e.type !== 'datepicker'
						&& e.type !== 'init'
						&& $this.data('datepicker')
					) { return; }
					
					if ($this.val().length) {
						$this.closest('.jsfl-wrap')
							.addClass(settings.class_populated)
							.removeClass(settings.class_focused);
						} 
					else {
						$this.closest('.jsfl-wrap')
							.removeClass(settings.class_populated + ' ' + settings.class_focused);
					}
				}
			);


			// On page load, make sure it looks good
			if (settings.feature_onLoadInit) {
				$scope.find(inputs).trigger('init.flowupLabelsEvt');
			}
		});
	};
})( jQuery );
